.ol-map {
  min-height: 500px;
  height: 500px;
  width: 100%;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-attribution {
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  max-width: 100%;
  right: 0;
  bottom: 0;
  border-radius: 0;
  display: flex;
  align-items: flex-end;
}

.ol-attribution button {
  flex: 1 0 auto;
}

.ol-satellite {
  right: 0.5em;
  top: 0.5em;
  position: absolute;
}

.ol-crop-display {
  left: 3em;
  top: 0.5em;
  position: absolute;
}

.ol-full-screen {
  top: 3em;
}

.ol-control button {
  background-color: rgba(0, 60, 136, 0.7);
}

.ol-control:hover button {
  background-color: rgba(0, 60, 136, 0.9);
}

.ol-control .ol-layer-visibility-button {
  width: fit-content;
  font-weight: 500;
  padding: 0.5em 1em 0.5em;
  font-size: 1em;
}
